export default {
  "i18n_master_my_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Profil"])},
  "i18n_master_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
  "i18n_master_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen"])},
  "i18n_master_experts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experte Suche"])},
  "i18n_master_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
  "profil": {
    "null": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateieingabe"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss"])},
      "study_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachbereich"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkungen"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfeld"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufsbezeichnung"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
      "telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "basicdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundinformationen"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
      "my_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Sprachen"])},
      "formation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildung"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausbildung und/oder Studium"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufserfahrung"])},
      "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
      "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobbeschreibung"])},
      "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])},
      "team_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamgröße"])},
      "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikate"])},
      "certificate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifizierung/Qualifikation"])},
      "issued_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herausgegeben von"])},
      "valid_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erworben am"])},
      "section_my_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Skills"])},
      "msg_delete_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie dieses Element wirklich löschen?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
      "expert_opt_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Skills"])},
      "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage"])},
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfallsdatum"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichen"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
      "advanced_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Suche"])},
      "global_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Suche"])},
      "expert_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experten Suche"])},
      "expert_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])},
      "expert_must_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst Skill"])},
      "expert_must_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst Sprachen"])},
      "expert_opt_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Sprachen"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
      "reset_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtereingaben zurücksetzen"])}
    },
    "de": {
      "i18n_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateieingabe"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss"])},
      "study_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachbereich"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkungen"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfeld"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufsbezeichnung"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
      "telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "basicdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundinformationen"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen"])},
      "my_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Sprachen"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildung"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausbildung und/oder Studium"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufserfahrung"])},
      "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
      "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobbeschreibung"])},
      "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])},
      "team_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamgröße"])},
      "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikate"])},
      "certificate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifizierung/Qualifikation"])},
      "issued_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herausgegeben von"])},
      "valid_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erworben am"])},
      "section_my_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Skills"])},
      "msg_delete_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie dieses Element wirklich löschen?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
      "expert_opt_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Skills"])},
      "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage"])},
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfallsdatum"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichen"])},
      "Publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikationen"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
      "advanced_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Suche"])},
      "global_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Suche"])},
      "expert_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experten Suche"])},
      "expert_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])},
      "expert_must_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst Skill"])},
      "expert_must_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst Sprachen"])},
      "expert_opt_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Sprachen"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
      "reset_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtereingaben zurücksetzen"])}
    },
    "en": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
      "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
      "study_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study field"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
      "telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress"])},
      "basicdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
      "my_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My languages"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School or University"])},
      "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
      "team_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team size"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
      "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
      "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job description"])},
      "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
      "certificate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification/Qualification"])},
      "issued_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued by"])},
      "valid_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid from"])},
      "section_my_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My skills"])},
      "msg_delete_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this item?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "expert_opt_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional skills"])},
      "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "advanced_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced search"])},
      "global_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global search"])},
      "expert_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search expert"])},
      "expert_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
      "expert_must_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst skill"])},
      "expert_must_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst languages"])},
      "expert_opt_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional languages"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reset"])},
      "reset_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filter"])}
    }
  },
  "Publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikationen"])},
  "i18n_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "i18n_pflichtfeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfeld"])},
  "i18n_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen"])},
  "i18n_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "i18n_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "i18n_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschließen"])},
  "i18n_section_my_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Sprachen"])},
  "i18n_section_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausbildung und/oder Studium"])},
  "i18n_section_study_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachbereich"])},
  "i18n_section_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "i18n_section_formation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildung"])},
  "i18n_section_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
  "i18n_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "i18n_ende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
  "i18n_section_remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkungen"])},
  "i18n_section_organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
  "i18n_section_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
  "i18n_section_job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobbeschreibung"])},
  "i18n_section_certificate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifizierung/Qualifikation"])},
  "i18n_section_issued_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herausgegeben von"])},
  "i18n_section_valid_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erworben am"])},
  "i18n_section_my_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Skills"])},
  "i18n_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "i18n_user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "i18n_user_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer Rollen"])},
  "i18n_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
  "i18n_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechte"])},
  "i18n_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "i18n_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
  "i18n_edit_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle bearbeiten"])},
  "i18n_role_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollenname"])},
  "i18n_privilegien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privilegien"])},
  "i18n_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "i18n_edit_role_user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rolle bearbeiten von ", _interpolate(_named("name"))])},
  "i18n_expert_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experten Suche"])},
  "i18n_expert_firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "i18n_expert_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "i18n_expert_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufsbezeichnung"])},
  "i18n_expert_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])},
  "i18n_expert_must_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst Skill"])},
  "i18n_expert_must_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst Sprachen"])},
  "i18n_expert_opt_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Sprachen"])},
  "i18n_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausblenden"])},
  "i18n_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigen"])},
  "i18n_conf_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
  "i18n_conf_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill"])},
  "i18n_conf_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
  "i18n_conf_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "i18n_conf_subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subkategorie"])},
  "i18n_conf_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
  "i18n_conf_rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung"])},
  "i18n_conf_relevance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevanz"])},
  "i18n_conf_max_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Wert"])},
  "i18n_conf_rating_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungsmodus"])},
  "i18n_conf_layer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schicht"])},
  "i18n_conf_parent_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oberkategorie"])},
  "i18n_conf_edit_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill bearbeiten"])},
  "i18n_conf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "i18n_conf_edit_rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung bearbeiten"])},
  "i18n_conf_rating_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungsname"])},
  "i18n_conf_max_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Skala"])},
  "i18n_conf_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darstellungsfarbe"])},
  "i18n_conf_edit_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen bearbeiten"])},
  "i18n_conf_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "i18n_conf_edit_classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie bearbeiten"])},
  "i18n_conf_classification_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriename"])},
  "i18n_field_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld erforderlich"])},
  "i18n_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "i18n_username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername erforderlich"])},
  "i18n_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "i18n_password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort erforderlich"])},
  "i18n_old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort"])},
  "i18n_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "i18n_repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
  "i18n_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
  "i18n_register_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldeformular"])},
  "i18n_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "i18n_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "i18n_change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
  "i18n_text_language_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachcode"])},
  "i18n_text_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungen"])},
  "i18n_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
  "i18n_relevance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevanz"])},
  "i18n_forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password vergessen"])},
  "i18n_send_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email senden"])},
  "i18n_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort Zurücksetzen"])},
  "i18n_tocken_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token abgelaufen"])}
}