<template>
  <v-list dense>
    <template v-for="(item, index) in certificates" :key="item.skill">
      <v-list-item  @mouseenter="hovering = true" @mouseleave="hovering = false">
        <v-container class="d-flex align-center">
          <v-list-item-icon>
          <v-icon class="mr-8">mdi-certificate</v-icon>
          </v-list-item-icon>
        <v-list-item-text>
          <h3>{{ item.name }}</h3>
          <v-list-item-subtitle>{{ item.issuedBy }}</v-list-item-subtitle>
          <v-list-item-subtitle class="text--primary">{{ formatValidDate(item.dateIssued) }}</v-list-item-subtitle>
        </v-list-item-text>
        <v-list-item-action class="ml-auto" v-show="hovering">
          <v-row class="d-flex align-center">
            <v-btn icon elevation="0" size="small" @click="deleteData(item.id)">
              <v-icon color="red lighten-1">mdi-delete</v-icon>
            </v-btn>
            <v-btn icon elevation="0" size="small" @click="editData(item)">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </v-row>
        </v-list-item-action>
        </v-container>
      </v-list-item>
      <v-divider v-if="index + 1 < certificates.length" :key="index"></v-divider>
    </template>
    <v-dialog v-model="dialog" max-width="310" persistent>
      <v-card>
        <v-card-title>{{ $t(`profil.${lang}.msg_delete_warning`) }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialog = false">{{ $t(`profil.${lang}.no`) }}</v-btn>
          <v-btn color="primary" @click="sendDelete()">{{ $t(`profil.${lang}.yes`) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  setup(props, {emit}) {
    const store = useStore();
    const dialog = ref(false);
    const selectedData = ref(0);
    const hovering = ref(false);
    
    const certificates = computed(() => {
      return store.state.users.certificates.sort((a, b) => {
        return (new Date(a.dateIssued) - new Date(b.dateIssued)) * -1;
      });
    });
    
    const isEditable = computed(() => store.state.general.selectedPageEditable);
    const lang = computed(() => store.state.general.profilLang);

    const formatValidDate = (date) => {
      return date ? `${new Date(date).getMonth() + 1}/${new Date(date).getUTCFullYear()}` : '';
    };

    const deleteData = (id) => {
      selectedData.value = id;
      dialog.value = !dialog.value;
    };

    const sendDelete = () => {
      store.dispatch('users/deleteCertificate', { id: selectedData.value });
      dialog.value = !dialog.value;
    };

    const editData = (item) => {
      emit('editCertificate', item);
    };

    return {
      certificates,
      isEditable,
      lang,
      dialog,
      deleteData,
      sendDelete,
      editData,
      formatValidDate,
      hovering,
    };
  },
};
</script>
